import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
const wom = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="wom">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Word of Mouth Technology</h1>
          <span className="highlight"></span>
        </span>
        <span>Assistive devices for Deaf and Hard of Hearing people</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/wom-featured-1.jpg'} alt="WOM" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
	              <img className="picture" src={'/img/single/cart-icon.png'} width="57" height="48" alt="Ecommerce" />
                <img className="picture" src={'/img/single/woocommerce-icon.png'} width="247" height="50" alt="Woocommerce" />
                </div>
            <a className="button visitwebsite" href="http://wom.com.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Word of Mouth Technology is a leading supplier of assistive devices for Deaf and Hard of Hearing people in Australia.</p>
        <p>In collaboration with Munch Design’s design vision to develop ecommerce and blog with Magento platform. Provided Search Engines Optimisation strategy and optimised to be responsive for desktop, tablet and smartphones.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/womcomau.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/womcomau_mobile.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/history-month" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">History Month</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/langley-group" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Langley Group</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default wom


export const Head = () => (
  <>
    <title>Word of Mouth Technology - Tobstar</title>
<meta name="description" content="Word of Mouth Technology is a leading supplier of assistive devices for Deaf and Hard of Hearing people in Australia." />
  </>
)